import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import Subsribe from "../components/newsletter/Subsribe";
import PageHeader from "../components/team/PageHeader";
import Teams from "../components/team/Teams";
import BrandCarousel from "../components/testimonial/BrandCarousel";
import { useNavigate } from 'react-router-dom';
import pantheon1 from "../logos/photos/pantheon1.jpg"
import pantheon2 from "../logos/photos/pantheon2.jpg"
import pantheon6 from "../logos/photos/pantheon6.png"
import pantheon4 from "../logos/photos/pantheon4.jpg"
import pantheon7 from "../logos/photos/first_publication.png"
import pantheon8 from "../logos/photos/CivilProtection.jpg"
import pantheon9 from "../logos/photos/pantheon8.jpg"
import pantheon10 from "../logos/photos/Malta1.jpg"
import pantheon14 from "../logos/photos/pantheon14.jpg"
import pantheon15 from "../logos/photos/Rescuer.jpg"
import pantheon17 from "../logos/photos/CERIS_event_cover.jpg"
import pantheon18 from "../logos/photos/interaigis_logo.png"
import pantheon24 from "../logos/photos/IDMIT.jpg"
import pantheon20 from "../logos/photos/ispc.jpg"
import pantheon21 from "../logos/photos/Thessaloniki.png"
import pantheon23 from "../logos/photos/dod.jpeg"
import pantheon25 from "../logos/photos/Pantheon_RKS02.jpg"
import pantheon26 from "../logos/photos/Nicosia Risk Forum.png"
import eurisy from "../logos/photos/Eurisy2.png"
import ethics from "../logos/photos/ethics.jpg"
import SubscribeForm from "../components/team/Subscribe";
import newsletter1 from "../logos/photos/pantheon-newsletter-1.png"
import pantheon16 from "../logos/photos/psce.png"
import model from "../logos/photos/Model.jpg"
import mapping from "../logos/photos/Mapping.jpg"
import img0 from "../logos/photos/ISCRAM_2024.png"


export default function News() {
  const navigate = useNavigate();

  function handleClick1() {

    navigate('/The_PANTHEON_project_kick-off_meeting');
  }
  function handleClick2() {

    navigate('/PANTHEONs_debut_exhibition:_DEFEA_2023');
  }
  function handleClick3() {

    navigate('/June_came_with_2_PANTHEON_online_workshops');
  }
  function handleClick4() {

    navigate('/Project_PANTHEON_travels_to_Vienna');
  }
  function handleClick5() {

    navigate('/Project_PANTHEON_welcomes_its_first_publication');
  }
  function handleClick6() {

    navigate('/PANTHEON_joins_Civil_Protection_efforts_at_Safe_Attica_2023');
  }
  function handleClick7() {

    navigate('/PANTHEON_project_at_the_Vienna_Security_Festival_2023');
  }
  function handleClick8() {

    navigate('/Introducing_PANTHEON_at_the_Eurisy’s_Satellite-based_Services_for_Disaster_Risk_Management');
  }
  function handleClick9() {

    navigate('/The 1st PANTHEON year comes full circle in Malta');
  }
  function handleClick10() {

    navigate('/PANTHEON Project at the RESCUER workshop');
  }
  function handleClick11() {

    navigate('/PANTHEON Project at the PSCE Spring Conference');
  }
  function handleClick12() {

    navigate('/Our PANTHEON Project at ISCRAM 2024');
  }
  function handleClick13() {

    navigate('/PANTHEON travels to Brussels for the CERIS event 2024');
  }
  function handleClick14() {

    navigate('/Spreading the word about PANTHEON in Interaigis 2024');
  }
  function handleClick15() {

    navigate('/The PANTHEON Project at the Thessaloniki International Fair 2023');
  }
  function handleClick16() {

    navigate('/PANTHEON Project at the ISPC Summer School');
  }
  function handleClick17() {

    navigate('/PANTHEON Project at DOD 2024');
  }
  function handleClick18() {

    navigate('/PANTHEON takes part in IDIMT Conference');
  }
  function handleClick19() {

    navigate('/PANTHEON at the 27th Conference “Crisis Situations Solution in Specific Environment”');
  }
  function handleClick20() {

    navigate('/PANTHEON at the NRF2024');
  }
  function blogClick1() {

    navigate('/The Pantheon Ethics');
  }
  function blogClick2() {

    navigate('/PANTHEONs approach to ethical technology development');
  }
  function blogClick3() {

    navigate('/Developing a PANTHEON Participatory Governance Model');
  }
  function blogClick4() {

    navigate('/Mapping the Future: Community-Based Disaster Management Strategies in Europe');
  }
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Newsroom" Parent="Pages" PageTitle="Newsroom" />
      <section className="promo-section mt-5 ptb-100" id='newses'>
        <div className="container">
          <div className="row" >
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
                  News
                  </h3>
            </div>
            <div class="container">
            <div class="card-deck my-3">

              
            <div class="card" style={{cursor:"pointer"}} onClick={handleClick20}>
<img class="card-img-top" src={pantheon26} alt="Card image cap"/>
<div class="card-body">
<h5 class="card-title">PANTHEON at the NRF2024</h5>
<p class="card-text">Between 4 and 5 December, the Nicosia Risk Forum (NRF2024) served as a meeting
point for interested parties from the disaster management and civil protection sectors.</p>
<p class="card-text"><small class="text-muted">12/12/2024</small> <span className="span2">Read more</span></p>

</div>
</div>
 

            <div class="card" style={{cursor:"pointer"}} onClick={handleClick19}>
<img class="card-img-top" src={pantheon25} alt="Card image cap"/>
<div class="card-body">
<h5 class="card-title">PANTHEON at the 27th Conference “Crisis Situations Solution in Specific Environment”</h5>
<p class="card-text">The 27th International Scientific Conference on Crisis Situations Solution in Specific
Environment took place on October 16-17, 2024 in Žilina. Slovakia.</p>
<p class="card-text"><small class="text-muted">22/10/2024</small> <span className="span2">Read more</span></p>

</div>
</div>
 
            <div class="card" style={{cursor:"pointer"}} onClick={handleClick18}>
      <img class="card-img-top" src={pantheon24} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON takes part in IDIMT Conference</h5>
        <p class="card-text">With more than 30 years of being an international scientific forum, the Interdisciplinary
        Information Management Talks (IDIMT Conference) ...</p>
        <p class="card-text"><small class="text-muted">10/09/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div> 



 
              


 



  
  
    
    
 
  
  
   

  </div>
              
  <div class="card-deck my-3">
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick17}>
      <img class="card-img-top" src={pantheon23} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON Project at DOD 2024</h5>
        <p class="card-text">This summer was particularly interesting, with events like the 13th ISPC Summer School in
June, and it continued so with the International Workshop on Dynamics of Disasters (DOD):...</p>
        <p class="card-text"><small class="text-muted">08/08/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>


  <div class="card" style={{cursor:"pointer"}} onClick={handleClick16}>
      <img class="card-img-top" src={pantheon20} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON Project at the ISPC Summer School</h5>
        <p class="card-text">This year, the 13th Summer School, organised by our PANTHEON partner, the Institute for
        Public Security of Catalonia (ISPC), took place on July 3rd and 4th.</p>
        <p class="card-text"><small class="text-muted">11/07/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

  <div class="card" style={{cursor:"pointer"}} onClick={handleClick13}>
      <img class="card-img-top" src={pantheon17} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON travels to Brussels for the CERIS event 2024</h5>
        <p class="card-text">This year, the CERIS event lasted three days (June 5-7) and centred on building disaster-
        resilient societies in Europe...</p>
        <p class="card-text"><small class="text-muted">14/06/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>



   

  </div>
  <div class="card-deck my-3">
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick12}>
      <img class="card-img-top" src={img0} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Our PANTHEON Project at ISCRAM 2024</h5>
        <p class="card-text">From the 25th to the 29th of May 2024, the ISCRAM conference was in Münster, Germany.The
        event follows a pracademic approach and was co-hosted by...</p>
        <p class="card-text"><small class="text-muted">11/06/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>


  <div class="card" style={{cursor:"pointer"}} onClick={handleClick11}>
      <img class="card-img-top" src={pantheon16} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON Project at the PSCE Spring Conference</h5>
        <p class="card-text">The PANTHEON project will attend the upcoming Public Safety Communication Europe (PSCE) Spring Conference on 4-5 June 2024.</p>
        <p class="card-text"><small class="text-muted">31/05/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

  <div class="card" style={{cursor:"pointer"}} onClick={handleClick14}>
      <img class="card-img-top" src={pantheon18} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Spreading the word about PANTHEON in Interaigis 2024</h5>
        <p class="card-text">The International Civil Protection Fire Safety & Special Equipment Exhibition for Local
        Authorities (InterAigis 2024) is an annual event that brings together...</p>
        <p class="card-text"><small class="text-muted">24/04/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>




  
    
    </div>
    <div class="card-deck my-3">
    <div class="card" style={{cursor:"pointer"}} onClick={handleClick10}>
      <img class="card-img-top" src={pantheon15} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON Project at the RESCUER workshop</h5>
        <p class="card-text">Between 20 and 21 February 2024, our PANTHEON project attended the post-pilot workshop of
the RESCUER project in Aix-en-Provence, ...</p>
        <p class="card-text"><small class="text-muted">05/04/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>


      
  <div class="card" style={{cursor:"pointer"}} onClick={handleClick9}>
      <img class="card-img-top" src={pantheon10} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title"> The 1st PANTHEON year comes full circle in Malta</h5>
        <p class="card-text">A year ago, on January 18-19, 2023, the PANTHEON consortium, consisting of 17 partners
(TWI Hellas, Airbus, M3 Systems Belgium,Software Imagination & Vision (SIMAVI), ...</p>
        <p class="card-text"><small class="text-muted">04/02/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

    <div class="card" style={{cursor:"pointer"}} onClick={handleClick8}>
      <img class="card-img-top" src={eurisy} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Introducing PANTHEON at the Eurisy’s Satellite-based Services for Disaster Risk Management </h5>
        <p class="card-text">Eurisy is a non-profit association bringing together space agencies, international organisations,
research institutions, and private entities working or interested in</p>
        <p class="card-text"><small class="text-muted">06/12/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>




  

   
   
    </div>
    <div class="card-deck my-3">
      
    <div class="card" style={{cursor:"pointer"}} onClick={handleClick7}>
      <img class="card-img-top" src={pantheon9} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON project at the Vienna Security Festival 2023</h5>
        <p class="card-text">From October 25th to 26th, security was the point of reference for the city of Vienna on Rathausplatz, the square in front of the town hall.There, the largest security exhibition, Wiener
Sicherheetfest </p>
        <p class="card-text"><small class="text-muted">01/11/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

    <div class="card" style={{cursor:"pointer"}} onClick={handleClick6}>
      <img class="card-img-top" src={pantheon8} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON joins Civil Protection efforts at Safe Attica 2023</h5>
        <p class="card-text">The 10th International Conference on Civil Protection and New Technologies, “Safe
Attica”, was a fitting event for the presentation of the PANTHEON project.</p>
        <p class="card-text"><small class="text-muted">02/10/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

    <div class="card" style={{cursor:"pointer"}} onClick={handleClick15}>
      <img class="card-img-top" src={pantheon21} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title"> The PANTHEON Project at the Thessaloniki International Fair 2023</h5>
        <p class="card-text">The 87th Thessaloniki International Fair (TIF), a unique platform that lasted for nine days,
        September 9-17, 2023, hosted 1500 exhibitors...</p>
        <p class="card-text"><small class="text-muted">20/09/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

   
   
   


   

    </div>

    <div class="card-deck my-3">
    <div class="card" style={{cursor:"pointer"}} onClick={handleClick5}>
      <img class="card-img-top" src={pantheon7} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title"> Project PANTHEON welcomes its first publication!</h5>
        <p class="card-text">On July 22nd, 2023, the article “Enhancing attack resilience of cyber-physical systems through
state dependency graph models” was published...</p>
        <p class="card-text"><small class="text-muted">25/07/2023</small> <span className="span2">Read more</span></p>
      </div>
    </div>


    <div class="card" style={{cursor:"pointer"}} onClick={handleClick4}>
      <img class="card-img-top" src={pantheon4} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title"> Project PANTHEON travels to Vienna </h5>
        <p class="card-text">After our plenary meeting in Athens, Vienna hosted representatives of the consortium
          partners...</p>
        <p class="card-text"><small class="text-muted">12/07/2023</small> <span className="span2">Read more</span></p>
      </div>
    </div>

    <div class="card" style={{cursor:"pointer"}} onClick={handleClick3}>
      <img class="card-img-top" src={pantheon6} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title"> June came with 2 PANTHEON online workshops</h5>
        <p class="card-text">The PANTHEON consortium had its first online workshops, one on the 8th and another on the
          28th of June...</p>
        <p class="card-text"><small class="text-muted">04/07/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>
   

 </div>
      <div class="card-deck my-3">
      <div class="card" style={{cursor:"pointer"}} onClick={handleClick2}>
      <img class="card-img-top" src={pantheon2} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title"> PANTHEON's debut exhibition: DEFEA 2023</h5>
        <p class="card-text">For the first time, the PANTHEON project had the opportunity to spread the word about its
mission to build robust infrastructure for optimising...</p>
        <p class="card-text"><small class="text-muted">13/06/2023</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>

      <div class="card" style={{cursor:"pointer"}} onClick={handleClick1}>
      <img class="card-img-top" src={pantheon1} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">The PANTHEON project kick-off meeting</h5>
        <p class="card-text">Between 18-19 January 2023, 17 partners from 9 countries came together to commence a 3-
year collaboration to enhance Community Disaster Resilience...</p>
        <p class="card-text" ><small class="text-muted">08/06/2023</small><span className="span2">Read more</span></p>
      </div>
      </div>
      </div>
    </div>
    
          
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" id='blog' style={{backgroundColor:'whitesmoke'}}>
        <div className="container">
        <div className="row" >
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
                  Blog
                  </h3>
            </div>
            <div class="container">

              
          
  <div class="card-deck my-3">

  <div class="card" style={{cursor:"pointer"}} onClick={blogClick4}>
      <img class="card-img-top" src={mapping} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Mapping the Future: Community-Based Disaster Management Strategies in Europe</h5>
        <p class="card-text">In the context of the PANTHEON project, the “Analysis of CBDRM National and Regional Policies, Existing
        Platforms and Uptakes”...</p>
        <p class="card-text"><small class="text-muted">22/10/2024</small> <span className="span2">Read more</span></p>
        
      </div> 
    </div>
    
  <div class="card" style={{cursor:"pointer"}} onClick={blogClick3}>
      <img class="card-img-top" src={model} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">Developing a PANTHEON Participatory Governance Model</h5>
        <p class="card-text">As part of PANTHEON's groundwork for disaster-resilient community approaches, a participatory
        governance model...</p>
        <p class="card-text"><small class="text-muted">05/06/2024</small> <span className="span2">Read more</span></p>
        
      </div> 
    </div>

  <div class="card" style={{cursor:"pointer"}} onClick={blogClick2}>
      <img class="card-img-top" src={pantheon14} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">PANTHEON's approach to ethical technology development</h5>
        <p class="card-text">One of the main objectives of PANTHEON is to create a system to support key actors in
disaster risk management...</p>
        <p class="card-text"><small class="text-muted">09/04/2024</small> <span className="span2">Read more</span></p>
        
      </div>
    </div>
   
  </div> <div class="card-deck my-3">
  <div class="card" style={{cursor:"pointer"}} onClick={blogClick1}>
      <img class="card-img-top" src={ethics} alt="Card image cap"/>
      <div class="card-body">
        <h5 class="card-title">The PANTHEON Ethics</h5>
        <p class="card-text">Research involving human subjects, as the PANTHEON project does, raises important and
complex ethical issues that need to be addressed and integrated...</p>
        <p class="card-text"><small class="text-muted">25/01/2024</small> <span className="span2">Read more</span></p>
        
      </div> 
      </div>    </div>
  
            </div>
         
          
          </div>
   
        </div>
      </section>
      <section className="promo-section ptb-100" id='newsletter' style={{backgroundColor:'white'}}>
        <div className="container">
          <div className="ow" >
            <div className="col-l">
            <h3 style={{color:'#ef8948'}}>
                  Newsletter
                  </h3>
                  <div class="container">
  <div class="card-deck my-3">
    <a href='https://mailchi.mp/9f2e5f43cc0b/project-pantheon-newsletter-no-1'>
  <div id="card2" style={{cursor:"pointer", maxWidth:'300px'}}>
      <img id="card2-img-top" style={{width:'60px'}} src={newsletter1} alt="Card image cap"/>
      <div id="card2-body" style={{float:'right', marginTop:'0px'}}>
        <h5 id="card2-title">Newsletter #1</h5>
  
        
      </div>
    </div></a>
    </div>
    <div class="card-deck my-3" >
    <a href='https://mailchi.mp/e75c010b4f61/project-pantheon-newsletter-no-10328882'>
  <div id="card2" style={{cursor:"pointer", maxWidth:'300px'}}>
      <img id="card2-img-top" style={{width:'60px'}} src={newsletter1} alt="Card image cap"/>
      <div id="card2-body" style={{float:'right', marginTop:'0px'}}>
        <h5 id="card2-title">Newsletter #2</h5>
  
        
      </div>
    </div></a>
    </div>

    <div class="card-deck my-3" >
    <a href='https://mailchi.mp/96c648596db3/project-pantheon-newsletter-no-10332715'>
  <div id="card2" style={{cursor:"pointer", maxWidth:'300px'}}>
      <img id="card2-img-top" style={{width:'60px'}} src={newsletter1} alt="Card image cap"/>
      <div id="card2-body" style={{float:'right', marginTop:'0px'}}>
        <h5 id="card2-title">Newsletter #3</h5>
  
        
      </div>
    </div></a>
    </div>
    
    </div>
             <SubscribeForm style={{justifyContent:'center'}}/>
            </div>
         
          
          </div>
   
        </div>
      </section>

      <Footer space />
    </Layout>
  );
}
