import React from "react";
import { LinkedInEmbed } from "react-social-media-embed";

export default function Contact({ bgColor }) {
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2 style={{color:'#ef8948'}}>Get in touch</h2>
                
              </div>
              <div className="footer-address">
               
                <ul>
               
                  <li>
                    <span>
                      Email :
                      <a href="mailto:hello@yourdomain.com" style={{color:'#ef8948'}}>
                      &nbsp; info@pantheon.eu
                      </a>
                    </span>
                  </li>
                  <br/>
                  <li>
                  <div  style={{ display: 'flex', justifyContent: 'center',zIndex:'1',position:'relative',display:'inline-block' }}>
  <LinkedInEmbed 
    url="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7270031485359833090"
    postUrl="https://www.linkedin.com/showcase/pantheon-project/posts/?feedView=all"
    width={300}
    height={230} 
  />
</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        autoComplete="off"
                        placeholder="Enter name"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        autoComplete="off"
                        placeholder="Enter email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
