import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import Subsribe from "../components/newsletter/Subsribe";
import PageHeader from "../components/team/PageHeader";
import Teams from "../components/team/Teams";
import BrandCarousel from "../components/testimonial/BrandCarousel";
import MyPDF1 from './pantheon-leaflet.pdf';
import MyPDF2 from './poster-pantheon.pdf';
import icon from './pdficon2.png'
import del1 from '../deliverables/D1.2. Periodic report on DRS stakeholders’ inputs - M10.pdf'
import del2 from '../deliverables/D1.5 Data Management Plan-M6.pdf'

import del3 from '../deliverables/D2.1. Community based DRM analysis and Regional Ecosystem.pdf'
import del4 from '../deliverables/D2.2. Multi-Hazards_risk data and assessment report.pdf'
import del5 from '../deliverables/D2.3 Community Vulnerability and capacity assessments (VCAs) report.pdf'
import del6 from '../deliverables/D2.4 Scientific & Technical Capacities Report.pdf'
import del7 from '../deliverables/D2.5 Participatory governance model and recommendations.pdf'
import del8 from '../deliverables/D2.6 PANTHEON Disaster-Resilient Communities report.pdf'

import del9 from '../deliverables/D3.1 PANTHEON Technology Roadmap for Disaster Resilient Communities.pdf'
import del10 from '../deliverables/D3.2. Report on Participatory Design.pdf'
import del11 from '../deliverables/D3.3 SCDT Conceptual Model.pdf'
import del12 from '../deliverables/D3.4 Data Delivery Scheme for CBDRM.pdf'
import del13 from '../deliverables/D3.5 Ethical, Legal and Societal PANTHEON design considerations.pdf'
import del14 from '../deliverables/D3.6 Use Case Scenarios.pdf'
import del15 from '../deliverables/D3.7 Overall Architecture and High level Functionalities.pdf'
import del16 from '../deliverables/D8.1 Report of Pilots_ operation scenarios-M14.pdf'
import del17 from '../deliverables/D9.3 - Interim Report on Communication and Dissemination Activities.pdf'




export default function Outcomes() {
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Outcomes" Parent="Pages" PageTitle="Outcomes" />
      <section className="promo-section mt-5 ptb-100" id='deliverables'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
            Public Deliverables
                  </h3>
                  <br/>
                <h4 style={{fontSize:'18px'}}>Work Package 1</h4>
                  <ul>
                    <li><a href={del1} target="blank">D1.2. Periodic report on DRS stakeholders’ inputs</a> </li>
                    <li><a href={del2} target="blank">D1.5 Data Management Plan</a> </li>
                  </ul>
                <br/>
                  <h4 style={{fontSize:'18px'}}>Work Package 2</h4>
                  <ul>
                    <li><a href={del3} target="blank">D2.1. Community-based DRM Analysis and Regional Ecosystem</a> </li>
                    <li><a href={del4} target="blank">D2.2. Multi-Hazards_risk data and assessment report</a> </li>
                    <li><a href={del5} target="blank">D2.3 Community Vulnerability and capacity assessments (VCAs) report</a> </li>
                    <li><a href={del6} target="blank">D2.4 Scientific & Technical Capacities Report</a> </li>
                    <li><a href={del7} target="blank">D2.5 Participatory governance model and recommendations</a> </li>
                    <li><a href={del8} target="blank">D2.6 PANTHEON Disaster-Resilient Communities Report</a> </li>
                  </ul>
                  <br/>
                  <h4 style={{fontSize:'18px'}}>Work Package 3</h4>
                  <ul>
                    <li><a href={del9} target="blank">D3.1 PANTHEON Technology Roadmap for Disaster Resilient Communities</a> </li>
                    <li><a href={del10} target="blank">D3.2. Report on Participatory Design</a> </li>
                    <li><a href={del11} target="blank">D3.3 SCDT Conceptual Model</a> </li>
                    <li><a href={del12} target="blank">D3.4 Data Delivery Scheme for CBDRM</a> </li>
                    <li><a href={del13} target="blank">D3.5 Ethical, Legal and Societal PANTHEON Design Considerations</a> </li>
                    <li><a href={del14} target="blank">D3.6 Use Case Scenarios</a> </li>
                    <li><a href={del15} target="blank">D3.7 Overall Architecture and High-level Functionalities</a> </li>
                  </ul>
                  <br/>
                  <h4 style={{fontSize:'18px'}}>Work Package 8</h4>
                  <ul>
                    <li><a href={del16} target="blank">D8.1 Report of Pilots' Operation Scenarios</a> </li>

                  </ul>
                  <br/>
                  <h4 style={{fontSize:'18px'}}>Work Package 9</h4>
                  <ul>
                    <li><a href={del17} target="blank">D9.3 - Interim Report on Communication and Dissemination Activities</a> </li>
            
                  </ul>

            </div>
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" style={{backgroundColor:'whitesmoke'}} id='publications'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
            Publications
                  </h3>
             
            </div>
         
          
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" id='material'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#ef8948'}}>
            Promotional Material
                  </h3>
                  <ul style={{listStyle:'none'}}>
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a style={{color:'#292929'}}id='newsletter' href={MyPDF1} download="Pantheon Leaflet.pdf">PANTHEON Leaflet <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>
              </li> 
    
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a style={{color:'#292929'}} id='newsletter' href={MyPDF2} download="Pantheon Poster.pdf">PANTHEON Poster <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>
              </li> 
          </ul>
             
            </div>
         
          
          </div>
   
        </div>
      </section>

      <Footer space />
    </Layout>
  );
}
